import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },
  {
    path: 'categories',
    loadChildren: () => import("../../components/categories/categories.module").then(m => m.CategoriesModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import("../../components/transactions/transactions.module").then(m => m.TransactionsModule)
  },
  {
    path: 'content',
    loadChildren: () => import("../../components/content/content.module").then(m => m.ContentModule)
  },
  {
    path: 'recepts',
    loadChildren: () => import("../../components/recepts/recepts.module").then(m => m.ReceptsModule)
  },
  {
    path: 'courses',
    loadChildren: () => import("../../components/courses/courses.module").then(m => m.CoursesModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import("../../components/notifications/notifications.module").then(m => m.NotificationsModule)
  },
  {
    path: 'banners',
    loadChildren: () => import("../../components/banners/banners.module").then(m => m.BannersModule)
  },
  {
    path: 'sort/:id',
    loadChildren: () => import("../../components/sort/sort.module").then(m => m.SortModule)
  },
  {
    path: 'dash',
    loadChildren: () => import("../../components/dash/dash.module").then(m => m.DashModule)
  }
];
